html {
  width: 100%;
  height: 100%;
  display: flex;
}

body {
  flex: 1;
  display: flex;
  margin: 0;
}

#root {
  flex: 1;
  display: flex;
}
